
import { FormPage } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { InputText } from '@/components/inputs'
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'
import FieldCheckbox from '@/components/forms/fields/FieldCheckbox.vue'
import FieldText from '@/components/forms/fields/FieldText.vue'
import BusinessBranchFeedbackCreateDto
  from 'tradingmate-modules/src/models/api/businesses/branches/Feedback/BusinessBranchFeedbackCreateDto'
import BusinessBranchFeedbackType
  from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchFeedbackType'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'

@Component({
  components: {
    FieldText,
    FieldCheckbox,
    FormCheckboxField,
    InputText
  }
})
export default class CancelMembership extends Mixins(FormPage) {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch;
  private membershipCancelled = false
  private option1 = false
  private option2 = false
  private option3 = false
  private option4 = false
  private feedback = ''
  mounted (): void {
    this.loading = false
  }

  private cancellingMembership = false
  onCancel (): void {
    if (!this.branch) return
    this.cancellingMembership = true
    Services.API.Businesses.MembershipCancel(this.branch.BusinessBranchId)
      .then((_result) => {
        console.log('membershipCancelled')
        this.membershipCancelled = true
      })
  }

  private feedbackSubmitted = false
  onSubmitFeedback (): void {
    if (!this.branch) return
    let feedbackPartial = ''
    if (this.option1)
    {
      feedbackPartial = "I'm no longer planning to operate this business"
    } else if (this.option2)
    {
      feedbackPartial = 'I did not receive 100% ROI on the cost of the listing'
    } else if (this.option3)
    {
      feedbackPartial = "I wasn't able to attend the functions"
    } else if (this.option4)
    {
      feedbackPartial = "I didn't think there was any value in the advertising"
    }
    feedbackPartial = feedbackPartial + ' - ' + this.feedback

    const createDto: BusinessBranchFeedbackCreateDto =
      {
        Type: BusinessBranchFeedbackType.CancellationRequest,
        Feedback: feedbackPartial
      }

    Services.API.Businesses.FeedbackCreate(this.branch.BusinessBranchId, createDto)
      .then((_result) => {
        this.feedbackSubmitted = true
      })
  }

  onCheckInputed (input: number): void {
    this.option1 = false
    this.option2 = false
    this.option3 = false
    this.option4 = false
    if (input === 1) this.option1 = true
    if (input === 2) this.option2 = true
    if (input === 3) this.option3 = true
    if (input === 4) this.option4 = true
  }
}
